.lines-wrapper {
  z-index: 0;
  width: 100%;
  position: relative;
  transform: rotate(140deg) translateY(-170px) scale(1.5);

  &:before {
    z-index: 30;
    content: "";
    transform: rotate(40deg);
    @apply absolute inset-0 hidden h-full w-full md:block;
    // overflow: visible;
    background: linear-gradient(
      to right,
      rgb(0, 0, 0) 10%,
      rgba(0, 0, 0, 0) 30%
    );

    @screen 5xl {
      background: linear-gradient(
        to right,
        rgb(0, 0, 0) 24%,
        rgba(0, 0, 0, 0) 30%
      );
    }
  }

  &:after {
    z-index: 30;
    content: "";
    transform: rotate(40deg);
    @apply absolute inset-0 hidden h-full w-full md:block;
    // overflow: visible;
    background: linear-gradient(
      to left,
      rgb(0, 0, 0) 10%,
      rgba(0, 0, 0, 0) 30%
    );
  }

  @screen md {
    transform: rotate(140deg) translateY(-170px) scale(2);
  }

  @screen lg {
    transform: rotate(140deg) translateY(0) scale(1.2);
  }

  @screen 3xl {
    transform: rotate(140deg) translateY(0) scale(1.4);
  }

  @screen 4xl {
    transform: rotate(140deg) translateY(0) scale(1.6);
  }
}

.waves-lines {
  @apply mx-auto overflow-visible 3xl:rounded-[150px];

  transform: scale(1.8) translateY(50%);

  @screen md {
    transform: scale(1.8) translateY(35%);
  }

  @screen lg {
    transform: translateY(0%) scale(1.1);
  }

  path {
    fill: none;
    stroke: url(#lineGradient);
    stroke-width: 0.5px;
    vector-effect: non-scaling-stroke;

    @screen lg {
      stroke-width: 1px;
    }
  }
}

.animated-lines {
  @apply overflow-visible;
}

.text {
  &--light {
    @apply leading-9 text-white/50;
  }

  &--dark {
    @apply leading-9 text-black/50;
  }

  &--paragraph {
    @apply text-base leading-loose lg:text-lg 3xl:text-xl;

    @screen 4xl {
      font-size: 1.3rem;
      line-height: 1.85;
    }

    strong {
      @apply text-black;
    }
  }
}

.section {
  &-heading {
    font-size: 2rem;
    @apply leading-normal lg:text-5xl;

    @screen 2xl {
      font-size: 3.5rem;
    }

    @screen 3xl {
      font-size: 4rem;
    }

    @screen 4xl {
      font-size: 70px;
    }
  }

  &-paragraph {
    @apply text-base leading-normal 3xl:text-3xl;
  }
}

// Overrides for third party styles of premade components

.MuiSnackbar-root {
  z-index: 10001 !important;
}

.MuiAlert-root {
  align-items: center;

  .MuiAlert-action {
    padding-top: 0;
  }
}

.MuiDialog-root {
  z-index: 10000 !important;
}

.MuiDialog-container.MuiDialog-scrollPaper {
  align-items: center;

  & > div {
    width: auto;
    justify-content: center;
    background-color: transparent;
  }
}

.dialog--fullscreen__close {
  top: -2rem !important;
  right: 0rem !important;
  position: absolute !important;
}

.MuiAccordion-root,
.MuiAccordion-root.Mui-expanded,
.MuiAccordionSummary-content,
.MuiAccordionSummary-contentGutters.Mui-expanded {
  margin: 0 !important;
}

.MuiButtonBase-root,
.MuiButtonBase-root.Mui-expanded {
  min-height: 48px !important;
}

.snackbar-default {
}

.swiper {
  &.no-rtl {
    .swiper-wrapper {
      direction: ltr;
      text-align: initial !important;
    }
  }
}

.menu {
  &-docs {
    @apply left-0 lg:-left-2;
  }

  &-community {
    @apply left-0 lg:-left-2;
  }

  &-soultion {
    @apply -left-2;
  }

  &-lang {
    @apply -right-10;
  }
}

.header {
  &__logo {
    @apply lg:-left-4;
  }
}

.rtl {
  .header {
    &__logo {
      @apply lg:-right-4;

      @screen lg {
        left: unset;
      }
    }
  }

  .menu {
    &-docs {
      left: unset;
      @apply right-0 lg:-right-2;

      @screen lg {
        left: unset;
      }
    }

    &-community {
      left: unset;
      right: 0;

      @screen lg {
        left: unset;
        @apply right-2;
      }
    }

    &-soultion {
      left: unset;
      @apply -right-2;
    }

    &-lang {
      right: unset;
      @apply -left-10;
    }
  }
}

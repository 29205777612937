.footer {
  &__social-link {
    &-container {
      width: calc(45px * 3 + 1rem * 2);
      @apply flex flex-wrap justify-between gap-4;

      @screen lg {
        width: auto;
        @apply flex-nowrap items-center justify-center;
      }
    }

    &-wrapper {
      flex: 1 1 calc(33.333% - 1rem);

      @screen lg {
        flex: 1 1 auto;
      }
    }

    height: 45px;
    width: 45px;
    @apply overflow-visible rounded-full bg-white/25 p-3 duration-300;

    &:hover {
      background-color: #a20127;
    }
  }

  &__copyright {
    border-color: rgba(255, 255, 255, 0.15);
    @apply relative mt-16 flex w-full flex-col items-center justify-between gap-4 border-solid border-y py-8 lg:mt-48 lg:flex-row lg:items-stretch lg:gap-0 lg:py-16;

    &:after {
      @screen lg {
        content: "";
        height: 60%;
        @apply absolute left-1/2 top-1/2 w-px -translate-x-1/2 -translate-y-1/2 bg-white/10;
      }
    }
  }
}

.sponsored-slider--footer {
  box-shadow: 0 3px 10px -3px rgba(0, 0, 0, 0.3);
  @apply relative flex w-full items-center justify-start gap-4 overflow-hidden duration-300;

  &::before,
  &::after {
    content: " ";
    position: absolute;
    z-index: 9;
    width: 140px;
    height: 100%;
  }
  &::before {
    top: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  &::after {
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to left,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &__container {
    transform: translate3d(0, 0, 0);
    animation: translateInfinite 12s linear infinite;
    width: calc(5 * 140px);
    @apply flex h-full md:w-full shrink-0 grow items-stretch gap-4;
  }

  &__item {
    @apply flex h-auto basis-1/4 items-center justify-center;

    img {
      width: 140px;
      @apply object-contain;

      @screen 3xl {
        width: 170px;
      }
    }
  }

  @keyframes translateInfinite {
    100% {
      transform: translateX(calc(-100% - 1rem));
    }
  }
}

@use "mixins" as mixins;

.feature-indicator {
  &-1 {
    // bg-green-400
    @include mixins.pulse(74, 222, 128);
  }

  &-2 {
    // bg-purple-500
    @include mixins.pulse(168, 85, 247, 0.4);
  }

  &-3 {
    // bg-sky-500
    @include mixins.pulse(14, 165, 233, 0.8);
  }

  &-primary {
    // bg-primary-500
    @apply bg-primary-500;
    @include mixins.pulse(121, 0, 238, 0.8);
  }
}

.link {
  &--pulse {
    @apply inline-block rounded-full text-black;

    // bg-green-300
    @include mixins.pulse(134, 239, 172);
  }

  &--inactive {
    @apply text-black bg-[#8d8d8d] rounded-full inline-block;
  }

  &--expired {
    @apply text-black bg-red-400 rounded-full inline-block;
  }
}

.pulse {
  &--teal {
    // bg-teal-300
    @include mixins.pulse(94, 234, 212);
  }

  &--sky {
    // bg-sky-400
    @include mixins.pulse(56, 189, 248);
  }

  &--primary {
    @apply bg-primary-500;
    @include mixins.pulse(121, 0, 238, 0.8);
  }
}

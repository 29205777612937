.hero {
  &__title {
    @apply text-3xl leading-tight;

    @screen md {
      font-size: 52px;
      width: 450px;
    }

    @screen lg {
    }

    @screen 2xl {
      width: 525px;
      font-size: 60px;
    }

    @screen 3xl {
      width: 655px;
      font-size: 74px;
    }

    @screen 4xl {
      width: 710px;
      font-size: 83px;
    }
  }

  &__text {
    @apply w-full text-center text-base leading-loose lg:text-lg 3xl:text-xl;

    @screen md {
      width: 575px;
    }

    @screen lg {
      width: auto;
      @apply leading-loose text-left;
    }

    @screen 2xl {
      width: 575px;
    }

    @screen 3xl {
      width: 630px;
    }

    @screen 4xl {
      width: 660px;

      font-size: 1.3rem;
      line-height: 1.85;
    }
  }
}

.action-mail {
  @apply flex cursor-pointer items-center gap-4 text-base text-white duration-300 3xl:text-2xl;
}

.action-mail .button-mail {
  @apply duration-300;
}

.action-mail:hover .button-mail {
  color: white;
  background-color: #a20127;
}

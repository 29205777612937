@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800&family=Rubik:wght@500&family=Urbanist:wght@600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@200;400;500;700&family=Poppins:wght@400;500;600;800&family=Rubik:wght@500&family=Urbanist:wght@600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@200;400;500;700&family=Poppins:wght@400;500;600;800&family=Rubik:wght@300;400;500;600;700&family=Urbanist:wght@600;700&display=swap);
@font-face {
  font-family: "Extra Bold";
  src: url(./fonts/VioletSans-Regular.ttf);
  font-weight: bolder;
}
@font-face {
  font-family: "Regular";
  src: url(./fonts/VioletSans-Regular.ttf);
  font-weight: lighter;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

::selection {
  @apply bg-primary-500 text-white;
}

.scroolbox::-webkit-scrollbar {
  width: 5px;
}

/* Track  */
.scroolbox::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

/* / Handle / */

.scroolbox::-webkit-scrollbar-thumb {
  background: linear-gradient(90.1deg, #4f98d0 0.11%, #34d9b1 95.94%);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}
.Barlow {
  font-family: "Barlow";
}
.Rubik {
  font-family: "Rubik";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.navbg {
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: showBorder;
  animation-timing-function: ease-out;
  backdrop-filter: saturate(50%) blur(4px);
  -webkit-backdrop-filter: saturate(50%) blur(4px);
  background-color: #212129;
  background-image: radial-gradient(
    circle at center,
    rgba(0, 0, 0, 0.278) 0.06rem,
    rgba(0, 0, 0, 0.749) 0.65rem
  );
  background-repeat: round;
}
.word-container {
  white-space: pre-wrap;
}
.MuiPaper-elevation {
  border: none !important;
  box-shadow: none !important;
}

.navhover {
  /* @apply tw-relative tw-top-0 tw-duration-300 tw-bg-black/20; */

  background-image: radial-gradient(
    circle at center,
    rgba(0, 0, 0, 0.2784313725) 0.06rem,
    #000000bf 0.65rem
  );
  background-size: 8px 8px;
  background-repeat: round;

  backdrop-filter: saturate(50%) blur(4px);
  -webkit-backdrop-filter: saturate(50%) blur(4px);

  animation-name: showBorder;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

/* @keyframes showBorder {
      from {
        @apply tw-border-b tw-border-transparent;
      }
      to {
        @apply tw-border-white/10;
      }
    } */

.deepbg {
  background-image: url("./images/deepbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
}
.boxbg {
  background-image: url("./images/boxbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;
}

.bshadow {
  box-shadow: rgb(0, 0, 0) 0px 0px 35px 48px inset;
}
.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  /* overflow: hidden */
}
.rounborder {
  border-top: 2px solid #232323;
  border-bottom: 2px solid #232323;
}

.boderTop {
  border-top: 1px solid #333333;
}
.boderTopBottom {
  border-top: 1px solid #333333;
  border-bottom: 1px solid #333333;
}
.swiper-button-next:after {
  border-radius: 50%;
  background-color: #322b2b;
  color: white;
  padding: 15px 17px;
  font-size: 12px;
}

.swiper-button-prev:after {
  border-radius: 50%;
  background-color: #322b2b;
  color: white;
  padding: 15px 17px;
  font-size: 12px;
}
.swiper-button-next {
  width: 10px;
  height: 10px;
  font-size: 12px;
}
.custom-button-prev {
  font-size: 12px; /* Set the desired font size */
  width: 20px; /* Set the desired width */
  height: 20px; /* Set the desired height */
  /* Other styles for the previous button */
}
.swiper-pagination-bullet {
  background-color: #fff;
  color: #ffffff;
}
.blob.green {
  background: #86efac;
  box-shadow: 0 0 0 0 #86efac;
  animation: pulse-green 3s infinite;
}
.blob.blue {
  background: #a20127;
  box-shadow: 30px 30px 30px 30px #a20127;
  animation: pulse-blue 2s infinite;
}
.waves {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}

@keyframes pulse-blue {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 #a20127;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 18px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }
}

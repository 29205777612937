@mixin pulse($r, $g, $b, $delay: 0, $scale: true) {
  @apply scale-100;
  background-color: rgb($r, $g, $b);

  animation-delay: $delay;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  $animationName: unique-id();
  animation-name: #{$animationName};

  @if $scale {
    @keyframes #{$animationName} {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($r, $g, $b, 0.7);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba($r, $g, $b, 0);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($r, $g, $b, 0);
      }
    }
  } @else {
    @keyframes #{$animationName} {
      0% {
        box-shadow: 0 0 0 0 rgba($r, $g, $b, 0.7);
      }

      70% {
        box-shadow: 0 0 0 20px rgba($r, $g, $b, 0);
      }

      100% {
        box-shadow: 0 0 0 0 rgba($r, $g, $b, 0);
      }
    }
  }
}

@use "mixins";
@use "indicator";
@use "overrides";
@use "text";
@use "hero";
@use "button";
@use "waves";
@use "footer";

button:focus-visible {
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.main-container {
  @apply max-w-9xl;
}

.rtl {
  direction: rtl;
  text-align: right !important;

  * {
    direction: rtl;
    text-align: right;
  }

  .no-rtl {
    direction: ltr;
    text-align: initial !important;
  }
}
